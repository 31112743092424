import React from 'react';
import './Navigation.scss';
import { Link, NavLink } from 'react-router-dom';

export const Navigation: React.FC = () => {
  return (
    <nav className="nav">
      <div className="nav__content nav__box">
        <div className="nav__left">
          <Link to="/" className="icon icon--logo">
            <img src="img/nav/logo.svg" alt="logo" />
          </Link>

          <ul className="nav__ul">
            <li className="nav__list">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? 'nav__link nav__link--active' : 'nav__link'
                }
              >
                About
              </NavLink>
            </li>
            <li className="nav__list">
              <NavLink
                to="/tokens"
                className={({ isActive }) =>
                  isActive ? 'nav__link nav__link--active' : 'nav__link'
                }
              >
                Tokens
              </NavLink>
            </li>
            <li className="nav__list">
              <NavLink
                to="https://opensea.io/collection/mriya-donation"
                target='_blank'
                className={({ isActive }) =>
                  isActive ? 'nav__link nav__link--active' : 'nav__link'
                }
              >
                OpenSea
              </NavLink>
            </li>
            <li className="nav__list">
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive ? 'nav__link nav__link--active' : 'nav__link'
                }
              >
                Contact
              </NavLink>
            </li>
          </ul>

          <Link to="/menu" className="icon icon--block icon--menu">
            <img src="img/nav/menu.svg" alt="menu" />
          </Link>
        </div>
      </div>
    </nav>
  );
};
