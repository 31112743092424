import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { setErrorCheckout, setSuccessCheckout, setTotalAmount } from '../../../../features/tokenSlice';
import { BASE_GATEWAY } from '../../../../vars';

interface ModalProps {
  isOpen: boolean;
  id: number;
  onClose: () => void;
  details: any;
  tokensAmount: BigInt;
  onClick?: () => void;
  price?: any;
  status?: any;
  networkError: boolean;
  checkNetwork: () => void;
  isMobile: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  isMobile,
  checkNetwork,
  networkError,
  id,
  onClose,
  details,
  onClick,
  tokensAmount,
  price,
  status,
}) => {
  const { walletAddress, totalAmount, successCheckout, errorCheckout } = useAppSelector(state => state.token);
  const dispatch = useAppDispatch();
  const [newAmount, setNewAmount] = useState(totalAmount.toString());
  const [canEdit, setCanEdit] = useState(false);
  const [modalLoading, setModalLoading] = useState<{ [key: number]: boolean }>({});

  const totalPrice = useMemo(() => {
    const priceBigInt = BigInt(Math.floor((price || 0) * 1e18));
    const totalAmountBigInt = BigInt(totalAmount);
    const resultBigInt = priceBigInt * totalAmountBigInt;
    const formattedResult = Number(resultBigInt) / 1e18;
    return formattedResult.toFixed(16).replace(/\.?0+$/, '');
  }, [totalAmount, price]);

  useEffect(() => {
    if (successCheckout) {
      setModalLoading(prevState => ({ ...prevState, [id]: false }));
      status.title = 'Success';
      status.className = 'modal__buttons--buy-success';
    } else if (errorCheckout) {
      setModalLoading(prevState => ({ ...prevState, [id]: false }));
      status.title = 'Failed';
      status.className = 'modal__buttons--buy-failed';
    } else {
      status.title = 'Checkout';
      status.className = 'modal__buttons--buy-button';
    }

    const timer = setTimeout(() => {
      dispatch(setSuccessCheckout(false));
      dispatch(setErrorCheckout(false));
      setModalLoading(prevState => ({ ...prevState, [id]: false }));
    }, 1000);

    return () => clearTimeout(timer);
  }, [successCheckout, errorCheckout, status, dispatch, id]);

  if (!isOpen) {
    return null;
  }

  const quantityAmount = +tokensAmount.toString() <= 0 ? 1000 : +tokensAmount.toString();
  const handlePlusQuantity = () => {
    if (+totalAmount.toString() >= quantityAmount) {
      return;
    }

    const amount = totalAmount + 1;
    dispatch(setTotalAmount(amount));
  };

  const handleMinusQuantity = () => {
    if (totalAmount === 1) {
      return;
    }
    const amount = totalAmount - 1;
    dispatch(setTotalAmount(amount));
  };

  const handleCheckoutDrone = () => {
    setModalLoading(prevState => ({ ...prevState, [id]: true }));
    if (onClick) {
      onClick();
    }
  };

  const handleChangeTitle = () => {
    if (newAmount) {
      if (
        +newAmount.trim() < 1 ||
        +newAmount.trim() > quantityAmount ||
        isNaN(+newAmount.trim())
      ) {
        return;
      }

      dispatch(setTotalAmount(+newAmount.trim()));
    } else {
      dispatch(setTotalAmount(1));
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleChangeTitle();
      setCanEdit(false);
    }

    if (event.key === 'Escape') {
      event.preventDefault();
      setNewAmount(totalAmount.toString());
      setCanEdit(false);
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    handleChangeTitle();
    setCanEdit(false);
  };

  const handleValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAmount(event.target.value);
  };

  const handleDoubleClick = () => {
    if (!canEdit) {
      setCanEdit(true);
    }
  };

  return ReactDOM.createPortal(
    <div className="modal modal__overlay" onClick={onClose}>
      <div className="modal modal__content" onClick={(e) => e.stopPropagation()}>
        <div className="modal__top">
          <h2 className="modal__title">{details?.name}</h2>
          <div className="modal__top--buttons">
            <button className="modal__close" onClick={onClose}>
              <img src="img/nav/close.svg" alt="close" />
            </button>
          </div>
        </div>
        <div className="modal__data">
          <aside className="modal__img">
            <img
              className="drone__image"
              style={{ width: '300px' }}
              src={`${BASE_GATEWAY}${details?.image.split('//')[1]}`}
              alt="token"
            />
          </aside>
          <aside className="modal__info">
            <p className="modal__text">
              {details?.description.split('\n\n').map((item: string, index: number, array: any[]) => (
                <React.Fragment key={index}>
                  {item}
                  {index < array.length - 1 && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                </React.Fragment>
              ))}
            </p>
            {isMobile ? (
              <div className="modal__mobile-message">
                You can only buy tokens on desktop
              </div>
            ) : (
              <div className="modal__buttons">
                {networkError ? (
                  <button className="modal__buttons--network-error" onClick={checkNetwork}>
                    Switch to Ethereum Mainnet
                  </button>
                ) : walletAddress.length === 0 ? (
                  <h2 className="modal__buttons--text">You must firstly connect wallet</h2>
                ) : (
                  <>
                    <h2 className="modal__buttons--text">Total: {+totalPrice > 1 ? parseFloat(totalPrice) : totalPrice} ETH</h2>
                    <div className="modal__buttons--box">
                      <div className="modal__buttons--buttons">
                        <button
                          className="modal__buttons--buttons-button"
                          onClick={handleMinusQuantity}
                        >
                          <span>-</span>
                        </button>
                        {canEdit ? (
                          <form onSubmit={(e) => e.preventDefault()}>
                            <input
                              type="text"
                              className="modal__buttons--input"
                              placeholder="1"
                              value={newAmount}
                              onChange={handleValue}
                              onKeyDown={handleKeyPress}
                              onBlur={handleBlur}
                              autoFocus
                            />
                          </form>
                        ) : (
                          <span
                            className="modal__buttons--buttons-number"
                            onDoubleClick={handleDoubleClick}
                          >
                            {totalAmount}
                          </span>
                        )}
                        <button
                          className="modal__buttons--buttons-button"
                          onClick={handlePlusQuantity}
                        >
                          <span>+</span>
                        </button>
                      </div>
                      <button
                        className={status.className}
                        onClick={handleCheckoutDrone}
                      >
                        {modalLoading[id] ? 'Loading...' : status.title}
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </aside>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
