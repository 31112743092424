import React, { useEffect, useState } from 'react';
import './About.scss';
import { Link } from 'react-router-dom';
import { ContactPage } from '../Contact';

export const AboutPage: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 639)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 639)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [window.innerWidth])

  return (
    <>
      <section className="main container">
        <aside className="main__textblock">
          <h1 className="main__title">Support Ukraine with Ethereum Donations</h1>
          <p className="main__text">Join us to support Ukraine’s fight for freedom and survival through Ethereum donations and NFT technology.<br /> Each of donations is transparent and every donor receives a unique token demonstrating his/her impact.</p>
          <Link to='/tokens' className="main__button">TOKENS</Link>
        </aside>
        <aside className="main__img">
          <img src="img/soldier.png" alt="soldier" />
        </aside>
      </section>
      <section className="about container">
        <h1 className="about__title">Who We Are</h1>
        <p className="about__text">
          We are a charitable foundation created to support Ukraine in its fight for freedom and independence. Due to modern technologies, we use donations in Ethereum to bypass high bank fees and to provide assistance to those in need faster.
        </p>
          <img className="about__flag" src="img/flag.png" alt="flag" />
        <img className="about__drone1" src="img/drones/drone1.png" alt="drone1" />
      </section>
      <section className="about container">
        <h1 className="about__title">Our mission</h1>
        {/* <div className="about__example-box"> */}
          <p className="about__text">
            To make it easier for people all over the world to help Ukraine. <br />
            To ensure transparency and individuality of each donation using NFT technology. Each of our donator receives his token, which symbolizes his contribution and clearly demonstrates what the funds were spent on.
            {/* <br />
            <br />
            An example of a token that you will receive after converting your donations into life
            {isMobile ? ' ⭣' : ' ⭢'} */}
          </p>
          {/* <img src="img/example.jpg" alt="example" className="about__nft-example" /> */}
        {/* </div> */}
      </section>
      <section className="reason container">
        <h1 className="reason__title">How it works</h1>
        <div className="reason__block reason__block--1">
          <span className="reason__number">1</span>
          <aside className="reason__aside">
            <h4 className="reason__aside--title">Donation</h4>
            <p className="reason__aside--text">You make a donation in cryptocurrency on our platform.</p>
          </aside>
        </div>
        <div className="reason__block reason__block--2">
          <span className="reason__number">2</span>
          <aside className="reason__aside">
            <h4 className="reason__aside--title">Obtaining NFT</h4>
            <p className="reason__aside--text">In return, you receive an NFT that represents the object of your donation (e.g. a drone).</p>
          </aside>
        </div>
        <div className="reason__block reason__block--3">
          <span className="reason__number">3</span>
          <aside className="reason__aside">
            <h4 className="reason__aside--title">Image change (depends on NFT)</h4>
            <p className="reason__aside--text">After item production, the image on the NFT changes to a photo (e.g. with a real Ukrainian soldier holding your unique item with your individual number).</p>
          </aside>
        </div>
        <div className="reason__block reason__block--4">
          <span className="reason__number">4</span>
          <aside className="reason__aside">
            <h4 className="reason__aside--title">Individual history</h4>
            <p className="reason__aside--text">Your NFT shows your contribution, creating a unique connection between you and the help you have provided.</p>
          </aside>
        </div>
        <div className="about__buy">
          <span className="about__buy--text">Buy tokens right now?</span>
          <Link to='/tokens' className="about__buy--button">Tokens</Link>
        </div>
      </section> 
      <section className="about container">
        <h1 className="about__title">As result</h1>
        <p className="about__text">
          Joined together, we provide Ukrainian army with the necessary resources to protect and save Ukraine.
        </p>
        <img className="about__drone2" src="img/drones/drone2.png" alt="drone2" />
      </section>
      <section className="about container">
        <h1 className="about__title">Worth of Your Help</h1>
        <p className="about__text">
          Your support will enable us to provide necessary help faster and more efficiently. So, you contribute to the
          future of Ukraine.<br/>
          Every donation matters. United, we get a chance to become a part of great force.
        </p>
        <div className="about__buy">
          <span className="about__buy--text">Buy tokens right now?</span>
          <Link to='/tokens' className="about__buy--button">Tokens</Link>
        </div>
      </section>
      <ContactPage />
    </>
  );
};
