import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './menu.scss';

export const Menu: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <aside className="menu__page menu" id="menu">
      <div className="menu__block">
        <div className="menu__top">
          <Link to="/" className="icon icon--logo">
            <img src="img/nav/logo.svg" alt="logo" />
          </Link>
          <div onClick={handleGoBack} className="icon icon--block icon--close">
            <img src="img/nav/close.svg" alt="close" />
          </div>
        </div>
        <ul className="menu__ul">
          <li className="menu__list">
            <Link to="/" className="menu__link">
              About
            </Link>
          </li>
          <li className="menu__list">
            <Link to="/tokens" className="menu__link">
              Tokens
            </Link>
          </li>
          <li className="menu__list">
            <Link to="https://opensea.io/collection/mriya-donation" target="_blank" className="menu__link">
              OpenSea
            </Link>
          </li>
          <li className="menu__list">
            <Link to="/contact" className="menu__link">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
};
